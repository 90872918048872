import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loginUserData: localStorage.getItem("currentUser")
      ? JSON.parse(localStorage.getItem("currentUser"))
      : {},
    loginUserType: localStorage.getItem("type")
      ? JSON.parse(localStorage.getItem("type"))
      : "",

    LoginUserId: localStorage.getItem("id")
      ? JSON.parse(localStorage.getItem("id"))
      : 0,
  },
  mutations: {
    updateLoginUser(state, data) {
      state.loginUserData = data;
    },

    updateloginUserType(state, data) {
      state.loginUserType = data;
    },
    updateLoginUserId(state, data) {
      state.LoginUserId = data;
    },
  },
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
  },
});
