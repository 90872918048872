import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue"),
        },
        {
          path: "/complaints",
          name: "complaints",
          component: () => import("@/view/pages/Complaints/Listing.vue"),
        },

        {
          path: "/accounts",
          name: "accounts",
          component: () => import("@/view/pages/Accounts/Listing.vue"),
        },

        {
          path: "/riders",
          name: "riders",
          component: () => import("@/view/pages/Riders/Listing.vue"),
        },
        {
          path: "/addrider",
          name: "addrider",
          component: () => import("@/view/pages/Riders/SubmitForm.vue"),
        },

        {
          path: "/editrider",
          name: "editrider",
          component: () => import("@/view/pages/Riders/SubmitForm.vue"),
        },

        {
          path: "/reserve_booking",
          name: "reserve_booking",
          component: () => import("@/view/pages/ReserveBooking/Listing.vue"),
        },
        {
          path: "/reserve-booking-today",
          name: "reserve_booking_today",
          component: () => import("@/view/pages/ReserveBooking/Listing.vue"),
        },
        {
          path: "/add-reserve-booking",
          name: "add_reserve_booking",
          component: () => import("@/view/pages/ReserveBooking/SubmitForm.vue"),
        },

        {
          path: "/edit_reserve_booking",
          name: "edit_reserve_booking",
          component: () => import("@/view/pages/ReserveBooking/SubmitForm.vue"),
        },

        {
          path: "/staff-temperature",
          name: "staff_temperature",
          component: () => import("@/view/pages/StaffTemperature/Listing.vue"),
        },
        {
          path: "/add-staff-temperature",
          name: "add_staff_temperature",
          component: () =>
            import("@/view/pages/StaffTemperature/SubmitForm.vue"),
        },

        {
          path: "/edit-staff-temperature",
          name: "edit_staff_temperature",
          component: () =>
            import("@/view/pages/StaffTemperature/SubmitForm.vue"),
        },

        {
          path: "/vouchers",
          name: "vouchers",
          component: () => import("@/view/pages/Voucher/Listing.vue"),
        },

        {
          path: "/edit-vouchers",
          name: "edit_vouchers",
          component: () => import("@/view/pages/Voucher/SubmitForm.vue"),
        },

        {
          path: "/uservouchers",
          name: "uservouchers",
          component: () => import("@/view/pages/UserVoucher/Listing.vue"),
        },
        {
          path: "/customervouchers/:id",
          name: "customervouchers",
          component: () => import("@/view/pages/UserVoucher/Listing.vue"),
        },

        {
          path: "/payments",
          name: "payments",
          component: () => import("@/view/pages/Payments/Listing.vue"),
        },

        {
          path: "/notifications",
          name: "notifications",
          component: () => import("@/view/pages/Notifications/Listing.vue"),
        },
        {
          path: "/notificationform",
          name: "notifications-form",
          component: () => import("@/view/pages/Notifications/Form.vue"),
        },
        {
          path: "/user-list",
          name: "user-list",
          component: () =>
            import("@/view/pages/Notifications/AssignedUser.vue"),
        },
        {
          path: "/privacypolicy",
          name: "privacypolicy",
          component: () =>
            import("@/view/pages/Settings/privacyPolicy/Add.vue"),
        },
        {
          path: "/contactus",
          name: "contactus",
          component: () =>
            import("@/view/pages/Settings/contactUs/Listing.vue"),
        },
        {
          path: "/addcontact",
          name: "AddContact",
          component: () => import("@/view/pages/Settings/contactUs/Form.vue"),
        },

        {
          path: "/alergies",
          name: "alergies",
          component: () => import("@/view/pages/Alergies/Listing.vue"),
        },

        {
          path: "/builder",
          name: "builder",
          component: () => import("@/view/pages/Builder.vue"),
        },

        {
          path: "/user",
          name: "user",
          component: () => import("@/view/user/User.vue"),
        },
        {
          path: "/adduser",
          name: "adduser",
          component: () => import("@/view/user/Add.vue"),
        },
        {
          path: "/edituser",
          name: "edituser",
          component: () => import("@/view/user/Edit.vue"),
        },

        {
          path: "/deletedcustomer",
          name: "deletedcustomer",
          component: () => import("@/view/pages/DeletedCustomer/Listing.vue"),
        },

        {
          path: "/restaurant",
          name: "resturant",
          component: () => import("@/view/pages/Restaurant/Listing.vue"),
        },
        {
          path: "/addrestaurant",
          name: "addrestaurant",
          component: () => import("@/view/pages/Restaurant/SubmitForm.vue"),
        },

        {
          path: "/editrestaurant",
          name: "editrestaurant",
          component: () => import("@/view/pages/Restaurant/SubmitForm.vue"),
        },

        {
          path: "/restaurantorders",
          name: "restaurantorders",
          component: () => import("@/view/pages/Restaurant/OrderTableView.vue"),
        },

        {
          path: "/customer",
          name: "customer",
          component: () => import("@/view/pages/Customer/Listing.vue"),
        },
        {
          path: "/addcustomer",
          name: "addcustomer",
          component: () => import("@/view/pages/Customer/SubmitForm.vue"),
        },

        {
          path: "/editcustomer",
          name: "editcustomer",
          component: () => import("@/view/pages/Customer/SubmitForm.vue"),
        },

        {
          path: "/deletedusers",
          name: "deletedusers",
          component: () => import("@/view/pages/DeletedUser/Listing.vue"),
        },

        {
          path: "/users/restaurant",
          name: "restaurantuser",
          component: () => import("@/view/pages/RestaurantUser/Listing.vue"),
        },

        {
          path: "add/users/restaurant",
          name: "addrestaurantuser",
          component: () => import("@/view/pages/RestaurantUser/SubmitForm.vue"),
        },

        {
          path: "editrestaurantuser",
          name: "editrestaurantuser",
          component: () => import("@/view/pages/RestaurantUser/SubmitForm.vue"),
        },
        {
          path: "add/admin/user",
          name: "addadminuser",
          component: () => import("@/view/pages/AdminUser/SubmitForm.vue"),
        },

        {
          path: "editadminuser",
          name: "editadminuser",
          component: () => import("@/view/pages/AdminUser/SubmitForm.vue"),
        },

        {
          path: "/admin/users",
          name: "adminuser",
          component: () => import("@/view/pages/AdminUser/Listing.vue"),
        },

        {
          path: "/editcustomer",
          name: "editcustomer",
          component: () => import("@/view/pages/Customer/SubmitForm.vue"),
        },

        {
          path: "/orders",
          name: "orders",
          component: () => import("@/view/pages/Orders/Listing.vue"),
        },

        {
          path: "/addorder",
          name: "addorder",
          component: () => import("@/view/pages/Orders/SubmitForm.vue"),
        },

        {
          path: "/editorder",
          name: "editorder",
          component: () => import("@/view/pages/Orders/SubmitForm.vue"),
        },

        {
          path: "/orderdetail",
          name: "orderdetail",
          component: () => import("@/view/pages/Orders/Detail.vue"),
        },

        {
          path: "/discount",
          name: "discount",
          component: () => import("@/view/pages/Discount/Listing.vue"),
        },

        {
          path: "/add-discount",
          name: "adddiscount",
          component: () => import("@/view/pages/Discount/SubmitForm.vue"),
        },

        {
          path: "/editdiscount",
          name: "editdiscount",
          component: () => import("@/view/pages/Discount/SubmitForm.vue"),
        },

        {
          path: "/credential_request",
          name: "credential_request",
          component: () => import("@/view/pages/CredentialRequest/Listing.vue"),
        },

        {
          path: "/edit_credential_request",
          name: "edit_credential_request",
          component: () =>
            import("@/view/pages/CredentialRequest/SubmitForm.vue"),
        },

        {
          path: "/recommended_restaurant",
          name: "recommended_restaurant",
          component: () =>
            import("@/view/pages/RecommendedRestaurant/Listing.vue"),
        },

        {
          path: "/addmeunitem",
          name: "addmeunitem",
          component: () => import("@/view/pages/MenuItem/SubmitForm.vue"),
        },

        {
          path: "/meunitems",
          name: "meunitems",
          component: () => import("@/view/pages/MenuItem/Listing.vue"),
        },

        {
          path: "/editmeunitem",
          name: "editmeunitem",
          component: () => import("@/view/pages/MenuItem/SubmitForm.vue"),
        },

        {
          path: "/addextras",
          name: "addextras",
          component: () => import("@/view/pages/Extras/SubmitForm.vue"),
        },

        {
          path: "/extras",
          name: "extras",
          component: () => import("@/view/pages/Extras/Listing.vue"),
        },

        {
          path: "/editextras",
          name: "editextras",
          component: () => import("@/view/pages/Extras/SubmitForm.vue"),
        },

        {
          path: "/kitchens",
          name: "kitchens",
          component: () => import("@/view/pages/Kitchen/Listing.vue"),
        },

        {
          path: "/addkitchen",
          name: "addkitchen",
          component: () => import("@/view/pages/Kitchen/SubmitForm.vue"),
        },

        {
          path: "/reports",
          name: "reports",
          component: () => import("@/view/pages/Report/Listing.vue"),
        },

        {
          path: "/tax",
          name: "tax",
          component: () => import("@/view/pages/Tax/Listing.vue"),
        },

        {
          path: "/gutenstripefees",
          name: "gutenstripefees",
          component: () => import("@/view/pages/GutenStripeFee/Listing.vue"),
        },

        {
          path: "/setting",
          name: "setting",
          component: () =>
            import("@/view/pages/PaymentGatewaySetting/Listing.vue"),
        },

        {
          path: "/vue-bootstrap",
          name: "vue-bootstrap",
          component: () =>
            import("@/view/pages/vue-bootstrap/VueBootstrap.vue"),
          children: [
            {
              path: "alert",
              name: "vue-bootstrap-alert",
              component: () => import("@/view/pages/vue-bootstrap/Alert.vue"),
            },
            {
              path: "badge",
              name: "vue-bootstrap-badge",
              component: () => import("@/view/pages/vue-bootstrap/Badge.vue"),
            },
            {
              path: "button",
              name: "vue-bootstrap-button",
              component: () => import("@/view/pages/vue-bootstrap/Button.vue"),
            },
            {
              path: "button-group",
              name: "vue-bootstrap-button-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/ButtonGroup.vue"),
            },
            {
              path: "button-toolbar",
              name: "vue-bootstrap-button-toolbar",
              component: () =>
                import("@/view/pages/vue-bootstrap/ButtonToolbar.vue"),
            },
            {
              path: "card",
              name: "vue-bootstrap-card",
              component: () => import("@/view/pages/vue-bootstrap/Card.vue"),
            },
            {
              path: "carousel",
              name: "vue-bootstrap-carousel",
              component: () =>
                import("@/view/pages/vue-bootstrap/Carousel.vue"),
            },
            {
              path: "collapse",
              name: "vue-bootstrap-collapse",
              component: () =>
                import("@/view/pages/vue-bootstrap/Collapse.vue"),
            },
            {
              path: "dropdown",
              name: "vue-bootstrap-dropdown",
              component: () =>
                import("@/view/pages/vue-bootstrap/Dropdown.vue"),
            },
            {
              path: "embed",
              name: "vue-bootstrap-embed",
              component: () => import("@/view/pages/vue-bootstrap/Embed.vue"),
            },
            {
              path: "form",
              name: "vue-bootstrap-form",
              component: () => import("@/view/pages/vue-bootstrap/Form.vue"),
            },
            {
              path: "form-checkbox",
              name: "vue-bootstrap-form-checkbox",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormCheckbox.vue"),
            },
            {
              path: "form-file",
              name: "vue-bootstrap-form-file",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormFile.vue"),
            },
            {
              path: "form-group",
              name: "vue-bootstrap-form-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormGroup.vue"),
            },
            {
              path: "form-input",
              name: "vue-bootstrap-form-input",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormInput.vue"),
            },
            {
              path: "form-radio",
              name: "vue-bootstrap-form-radio",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormRadio.vue"),
            },
            {
              path: "form-select",
              name: "vue-bootstrap-form-select",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormSelect.vue"),
            },
            {
              path: "form-textarea",
              name: "vue-bootstrap-form-textarea",
              component: () =>
                import("@/view/pages/vue-bootstrap/FormTextarea.vue"),
            },
            {
              path: "image",
              name: "vue-bootstrap-image",
              component: () => import("@/view/pages/vue-bootstrap/Image.vue"),
            },
            {
              path: "input-group",
              name: "vue-bootstrap-input-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/InputGroup.vue"),
            },
            {
              path: "jumbotron",
              name: "vue-bootstrap-jumbotron",
              component: () =>
                import("@/view/pages/vue-bootstrap/Jumbotron.vue"),
            },
            {
              path: "layout-grid-system",
              name: "vue-bootstrap-layout-grid-system",
              component: () =>
                import("@/view/pages/vue-bootstrap/LayoutGridSystem.vue"),
            },
            {
              path: "link",
              name: "vue-bootstrap-link",
              component: () => import("@/view/pages/vue-bootstrap/Link.vue"),
            },
            {
              path: "list-group",
              name: "vue-bootstrap-list-group",
              component: () =>
                import("@/view/pages/vue-bootstrap/ListGroup.vue"),
            },
            {
              path: "media",
              name: "vue-bootstrap-media",
              component: () => import("@/view/pages/vue-bootstrap/Media.vue"),
            },
            {
              path: "modal",
              name: "vue-bootstrap-modal",
              component: () => import("@/view/pages/vue-bootstrap/Modal.vue"),
            },
            {
              path: "nav",
              name: "vue-bootstrap-nav",
              component: () => import("@/view/pages/vue-bootstrap/Nav.vue"),
            },
            {
              path: "navbar",
              name: "vue-bootstrap-navbar",
              component: () => import("@/view/pages/vue-bootstrap/Navbar.vue"),
            },
            {
              path: "pagination",
              name: "vue-bootstrap-pagination",
              component: () =>
                import("@/view/pages/vue-bootstrap/Pagination.vue"),
            },
            {
              path: "pagination-nav",
              name: "vue-bootstrap-pagination-nav",
              component: () =>
                import("@/view/pages/vue-bootstrap/PaginationNav.vue"),
            },
            {
              path: "popover",
              name: "vue-bootstrap-popover",
              component: () => import("@/view/pages/vue-bootstrap/Popover.vue"),
            },
            {
              path: "progress",
              name: "vue-bootstrap-progress",
              component: () =>
                import("@/view/pages/vue-bootstrap/Progress.vue"),
            },
            {
              path: "spinner",
              name: "vue-bootstrap-spinner",
              component: () => import("@/view/pages/vue-bootstrap/Spinner.vue"),
            },
            {
              path: "table",
              name: "vue-bootstrap-table",
              component: () => import("@/view/pages/vue-bootstrap/Table.vue"),
            },
            {
              path: "tabs",
              name: "vue-bootstrap-tabs",
              component: () => import("@/view/pages/vue-bootstrap/Tabs.vue"),
            },
            {
              path: "toasts",
              name: "vue-bootstrap-toasts",
              component: () => import("@/view/pages/vue-bootstrap/Toasts.vue"),
            },
            {
              path: "tooltip",
              name: "vue-bootstrap-tooltip",
              component: () => import("@/view/pages/vue-bootstrap/Tooltip.vue"),
            },
          ],
        },
        {
          path: "/vuetify",
          name: "vuetify",
          component: () => import("@/view/pages/vuetify/Vuetify.vue"),
          children: [
            {
              path: "alerts",
              name: "vuetify-alerts",
              component: () => import("@/view/pages/vuetify/Alerts.vue"),
            },
            {
              path: "avatars",
              name: "vuetify-avatars",
              component: () => import("@/view/pages/vuetify/Avatars.vue"),
            },
            {
              path: "badges",
              name: "vuetify-badges",
              component: () => import("@/view/pages/vuetify/Badges.vue"),
            },
            {
              path: "buttons",
              name: "vuetify-buttons",
              component: () => import("@/view/pages/vuetify/Buttons.vue"),
            },
            {
              path: "calendars",
              name: "vuetify-calendars",
              component: () => import("@/view/pages/vuetify/Calendars.vue"),
            },
            {
              path: "cards",
              name: "vuetify-cards",
              component: () => import("@/view/pages/vuetify/Cards.vue"),
            },
            {
              path: "chips",
              name: "vuetify-chips",
              component: () => import("@/view/pages/vuetify/Chips.vue"),
            },
            {
              path: "dialog",
              name: "vuetify-dialog",
              component: () => import("@/view/pages/vuetify/Dialog.vue"),
            },
            {
              path: "autocompletes",
              name: "vuetify-autocompletes",
              component: () =>
                import("@/view/pages/vuetify/forms/Autocompletes.vue"),
            },
            {
              path: "file-inputs",
              name: "vuetify-file-inputs",
              component: () =>
                import("@/view/pages/vuetify/forms/FileInputs.vue"),
            },
            {
              path: "forms",
              name: "vuetify-forms",
              component: () => import("@/view/pages/vuetify/forms/Forms.vue"),
            },
            {
              path: "selection-controls",
              name: "vuetify-selection-controls",
              component: () =>
                import("@/view/pages/vuetify/forms/SelectionControls.vue"),
            },
            {
              path: "selects",
              name: "vuetify-selects",
              component: () => import("@/view/pages/vuetify/forms/Selects.vue"),
            },
            {
              path: "textareas",
              name: "vuetify-textareas",
              component: () =>
                import("@/view/pages/vuetify/forms/Textareas.vue"),
            },
            {
              path: "text-fields",
              name: "vuetify-text-fields",
              component: () =>
                import("@/view/pages/vuetify/forms/TextFields.vue"),
            },
            {
              path: "simple-tables",
              name: "vuetify-simple-tables",
              component: () =>
                import("@/view/pages/vuetify/tables/SimpleTables.vue"),
            },
            {
              path: "data-tables",
              name: "vuetify-data-tables",
              component: () =>
                import("@/view/pages/vuetify/tables/DataTables.vue"),
            },
            {
              path: "tabs",
              name: "vuetify-tabs",
              component: () => import("@/view/pages/vuetify/Tabs.vue"),
            },
            {
              path: "timelines",
              name: "vuetify-timelines",
              component: () => import("@/view/pages/vuetify/Timelines.vue"),
            },
            {
              path: "tooltips",
              name: "vuetify-tooltips",
              component: () => import("@/view/pages/vuetify/Tooltips.vue"),
            },
            {
              path: "treeview",
              name: "vuetify-treeview",
              component: () => import("@/view/pages/vuetify/Treeview.vue"),
            },
          ],
        },
        {
          path: "/wizard",
          name: "wizard",
          component: () => import("@/view/pages/wizard/Wizard.vue"),
          children: [
            {
              path: "wizard-1",
              name: "wizard-1",
              component: () => import("@/view/pages/wizard/Wizard-1.vue"),
            },
            {
              path: "wizard-2",
              name: "wizard-2",
              component: () => import("@/view/pages/wizard/Wizard-2.vue"),
            },
            {
              path: "wizard-3",
              name: "wizard-3",
              component: () => import("@/view/pages/wizard/Wizard-3.vue"),
            },
            {
              path: "wizard-4",
              name: "wizard-4",
              component: () => import("@/view/pages/wizard/Wizard-4.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue"),
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue"),
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue"),
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue"),
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue"),
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue"),
        },
      ],
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login"),
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Register"),
        },
      ],
    },
    {
      path: "*",
      redirect: "/404",
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue"),
    },
  ],
});
