import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAhTq2jqNWgayYdJhDZ2XYUHHmJ0SD_aKU",
    libraries: "places",
  },
});

// import MockService from "@/core/mock/mock.service";
// import { VERIFY_AUTH } from "@/core/services/store/auth.module";
// import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
Vue.use(VueToast);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
import "@/core/plugins/perfect-scrollbar";
import "@/core/plugins/highlight-js";
import "@/core/plugins/inline-svg";
import "@/core/plugins/apexcharts";
import "@/core/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "./assets/css/custom-style.css";

// API service init
ApiService.init();

// Remove this to disable mock API
// MockService.init();
// Vue.prototype.$path = "http://production.meetmycar.com/api";
// Vue.prototype.$path = "http://skipshare-frontend.meetmycar.com";
// Vue.prototype.$path = "https://gutenapp.crunchyapps.com/api";
// Vue.prototype.$IMAGE_BASE_URL = "https://gutenapp.crunchyapps.com/Images/";

// Vue.prototype.$path = "https://backend.gutenappetite.de/api";
// Vue.prototype.$IMAGE_BASE_URL = "https://backend.gutenappetite.de/Images/";

// Vue.prototype.$path = "https://guten-backend.tajtrudering.de/api";
// Vue.prototype.$IMAGE_BASE_URL = "https://guten-backend.tajtrudering.de/Images/";

Vue.prototype.$path = "https://gutenappetite-backend.crunchyapps.com/api";
Vue.prototype.$IMAGE_BASE_URL =
  "https://gutenappetite-backend.crunchyapps.com/Images/";

// router.beforeEach((to, from, next) => {
//   // Ensure we checked auth before each page load.
//   Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);

//   // reset config to initial state
//   store.dispatch(RESET_LAYOUT_CONFIG);

//   // Scroll page to top on every route change
//   setTimeout(() => {
//     window.scrollTo(0, 0);
//   }, 100);
// });

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
